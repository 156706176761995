import React, { Component } from "react"
import { FiCast , FiLayers , FiUsers , FiMonitor, FiSmartphone, FiPrinter, FiThumbsUp, FiSettings } from "react-icons/fi";
import { Link } from "gatsby"
import Layout from "../../components/layout/Layout"
import PageHelmet from "../../components/Helmet"
import BGImage from "gatsby-background-image"

const DevelopmentServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Website Development',
        url: '/services/web',
        description: 'Gorgeous, high-performance websites, that you can maintain yourself.'
    },
    {
        icon: <FiLayers />,
        title: 'Web App Development',
        url: '/services/web',
        description: 'Highly scalable web applications your business can count on every time.'
    },
    {
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        url: '/services/mobile',
        description: 'Powerful mobile applications backed by the full power of the cloud.'
    },
]

const DesignServicesList = [
    {
        icon: <FiMonitor />,
        title: 'Website Design',
        url: '/services/',
        description: 'A completely custom website design can ensure your website stands out in a sea of templates.'
    },
    {
        icon: <FiPrinter />,
        title: 'Print Design',
        url: '/services/',
        description: 'Business cards, letterhead, advertising collateral. Amplify your message with eye catching artwork.'
    },
    {
        icon: <FiThumbsUp />,
        title: 'Branding Design',
        url: '/services/',
        description: 'We\'ll help you craft a powerful visual identity that sets your business apart from the competition.'
    },
]

const StrategyServicesList = [
    {
        icon: <FiUsers />,
        title: 'Digital Strategy',
        url: '/services/',
        description: 'Fractional CTO services pair you with an experienced executive to develop a tech roadmap and manage implementation.'
    },
    {
        icon: <FiSettings />,
        title: 'Product Development',
        url: '/services/',
        description: 'Don\'t have the technical experience in-house to get your idea to market? We can handle the project soup-to-nuts.'
    },

]



class ServicesIndex extends Component {
    render() {
        return(
            <>
                <PageHelmet pageTitle='Services' />
                <Layout>
                    {/* Start Banner Area */}
                    <BGImage
                        fluid={this.props.data.headerBackgroundImage.childImageSharp.fluid}>
                        <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">SERVICES</h2>
                                            <p>Your One-Stop-Shop for All Things Digital</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BGImage>
                    {/* End Banner Area */}

                    {/* Start Service Area */}
                    <a name="development" />
                    <div className="service-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Development</h2>
                                        <p>We build high quality software tailored to your specific needs fueled by decades of experience <br /> delivering software to the largest companies in the world.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row service-one-wrapper">
                                {DevelopmentServiceList.map( (val , i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <Link to={val.url}>
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Service Area */}

                    {/* Start Service Area */}
                    <a name="design" />
                    <div className="service-area ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Design</h2>
                                        <p>Ensure that your business puts its best foot forward every time.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row service-one-wrapper">
                                {DesignServicesList.map( (val , i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <Link to={val.url}>
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Service Area */}

                    {/* Start Service Area */}
                    <a name="strategy" />
                    <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Strategy</h2>
                                        <p>The modern technology landscape can be daunting. Leverage our decades of experience <br /> to make navigating it easier with less risk to your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row creative-service">
                                {StrategyServicesList.map( (val , i) => (
                                    // <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <Link to={val.url}>
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Service Area */}

                    {/* Start Service Area */}
                    {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Development</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row creative-service">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a className="text-center" href="/service-details">
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                    {/* End Service Area */}

                </Layout>
            </>
        )
    }
}

export default ServicesIndex

export const query = graphql`
    query {
        headerBackgroundImage: file(relativePath: { eq: "services-web-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`